import { Coordinates } from "Context";
import { useEffect, useState } from "react";
import { CountryCode } from "libphonenumber-js";

interface IpLocation {
  coordinates: Coordinates;
  countryCode: CountryCode;
  loading: null | boolean;
  error: null | string;
}

export const useIpLocation = (): IpLocation => {
  const [coordinates, setCoordinates] = useState<Coordinates>(null);
  const [countryCode, setCountryCode] = useState<CountryCode>(null);
  const [loading, setLoading] = useState<null | boolean>(null);
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    const api = process.env.REACT_APP_IPSTACK_PATH;
    const key = process.env.REACT_APP_IPSTACK_APIKEY;

    const getCoordinates = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${api}/check?access_key=${key}&fields=latitude,longitude,country_code`
        );
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        const data = await response.json();
        if (data) {
          setCoordinates({
            latitude: data.latitude,
            longitude: data.longitude,
          });
          setCountryCode(data.country_code);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getCoordinates();
  }, []);

  return { coordinates, countryCode, loading, error };
};
